<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <div class="navi-link" dark v-bind="attrs" v-on="on">
        <span class="navi-icon">
          <i class="flaticon-edit-1 text-warning"></i>
        </span>
        <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
      </div>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ $t('ACTION.UPDATE') }}
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="id"
          outlined
          dense
          v-model="list.id"
        ></v-text-field>
        <v-text-field
          label="Nomi"
          outlined
          dense
          v-model="list.name"
        ></v-text-field>

        <v-text-field
          label="Kod"
          outlined
          dense
          v-model="list.code"
        ></v-text-field>
        <v-autocomplete
          v-model="list.responsible"
          :items="staff"
          outlined
          dense
          item-text="full_name"
          item-value="id"
          label="Ma'sul nomi"
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="dialog = false"> Yopish </v-btn>
        <v-btn color="success" @click="send"> Yuborish </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      list: {}
    }
  },
  created() {
    this.list = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    staff() {
      return this.$store.state.requests.allStaff
    }
  },
  methods: {
    send() {
      const data = {
        name: this.list.name,
        code: this.list.code,
        responsible: this.list.responsible
      }
      console.log(data)
      this.$store.dispatch('updateSklad', { id: this.list.id, data })
      this.dialog = false
      this.name = ''
      this.code = ''
      this.responsible = ''
    }
  },
  props: {
    data: Object
  }
}
</script>
