<template>
  <div class="card-body">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      loading-text="Malumot yuklanmoqda..."
      :items="allSkladList"
      class="row-pointer"
      single-select
      @click:row="rowClick"
      item-key="id"
      hide-default-footer
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          allSkladList
            .map(function (x) {
              return x.id
            })
            .indexOf(item.id) + 1
        }}
      </template>
      <template v-slot:[`item.item`]="{ item }">
        <p class="m-0" v-if="item.responsible_description == null"></p>
        <p v-else class="m-0">
          {{
            item.responsible_description.staffpersonal_description.first_name +
            ' ' +
            item.responsible_description.staffpersonal_description.last_name
          }}
        </p>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <SkladAction :index="item"></SkladAction>
      </template>
    </v-data-table>
    <div class="text-left mt-5">
      <v-pagination
        v-model="currentPage"
        :total-visible="7"
        @input="getPostData(currentPage)"
        :length="Math.ceil(getCount.count / 10)"
      ></v-pagination>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import SkladAction from './SkladAction'
export default {
  components: { SkladAction },
  data: () => ({
    counter: 0, // count the clicks
    timer: null, // Needs to be specified here so it can be accessed on both clicks
    selectedId: -1,
    perPage: 10,
    currentPage: 1
  }),
  created() {
    this.currentPage = parseInt(this.$route.params.id, 10)
    this.getPostData(this.currentPage)
    this.$store.dispatch('getAllStaff')
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentPage = parseInt(id, 10)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'name'
        },
        {
          text: this.$t('TABLE_HEADER.CODE'),
          value: 'code'
        },
        {
          text: this.$t('TABLE_HEADER.RESPONSIBLE_NAME'),
          value: 'item'
        },
        {
          value: 'action',
          text: ''
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getCount() {
      return this.$store.state.requests.skladList
    },
    allSkladList() {
      var data = []
      data = this.$store.state.requests.skladList.results
      if (data !== undefined && data.length !== undefined) {
        data.forEach((element) => {
          if (element.responsible !== null) {
            element.responsibleName =
              element.warehouse_responsible.first_name +
              ' ' +
              element.warehouse_responsible.last_name
          }
        })
        return data
      } else return data
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/contractdetails/' + item.id + '/')
        row.select(false)
      }
    },
    getPostData(value) {
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          id: value,
          payload: this.$store.state.requests.filterData.data
        }
        this.$store.dispatch('contractsSearchPerPages', asd)
        if (this.$route.path !== '/allcontracts/' + value) {
          this.$router.push('/allcontracts/' + value)
        }
      } else {
        this.$store.dispatch('getSkladList', value)
        if (this.$route.path !== '/sklad/' + value) {
          this.$router.push('/sklad/' + value)
        }
      }
    }
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
